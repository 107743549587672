#projects{
    background-color: var(--black-color);
}

.app__projects{
    flex: 1;
    height: 100%;
    width: 100%;
    flex-direction: column;
    padding: 6rem 0rem 1rem;
    color: var(--white-color);
}

.app__projects-filter{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin: 4rem 0 2rem; 

    .app__projects-filter-item{
        padding: 0.5rem 1rem;
        margin: 0.5rem;
        background-color: var(--color-crimson);
        color: var(--black-color);
        cursor: pointer;
        text-transform: capitalize;
        border-radius: 0.5rem;
        font-weight: 500;
        transition: all 0.1s ease;
        font-family: var(--font-alt);

        &:hover{
            background-color: var(--color-golden);
        }

        @media screen and (min-width: 2000px) {
            padding: 1rem 2rem;
            border-radius: 0.85rem;
        }
    }

    .item-active{
        background-color: var(--color-golden);
    }
}

.app__projects-cards{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    a{
        text-decoration: none;
    }

    .app__projects-item{
        width: 300px;
        flex-direction: column;
        margin: 2rem;
        padding: 0.5rem;
        border-radius: 0.5rem;
        border: 2px solid var(--color-golden);
        cursor: pointer;
        transition: all 0.1s ease;
        &:hover{
            box-shadow: 0 0 25px var(--color-golden);
        }
        @media screen and (min-width:2000px) {
            width: 470px;
            padding: 1.25rem;
        }
        @media screen and (max-width:300px) {
            width: 100%;
            margin: 1rem;
        }
    }

}

.app__projects-img{
    width: 100%;
    height: 250px;
    position: relative;
    img{
        width: 100%;
        height: 100%;
        padding: 0.5rem;
        border-radius: 0.5rem;
        object-fit: contain;
    }

    @media screen and (min-width:2000px) {
        height: 400px;
    }
}

.app__projects-hover{
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($color: #000000, $alpha: 0.6);
    opacity: 0;
    transition: all 0.1s ease;
    div{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: rgba(0,0,0,0.5);
        color: var(--white-colorm);
        margin: 1rem;
        font-family: var(--font-alt);
        font-weight: 800;
        cursor: pointer;
        transition: all 0.1s ease;
        svg{
            width: 50%;
            height: 50%;
            color: var(--white-color);
        }
    }
}


.app__projects-content{
    padding: 0.5rem;
    display: flex;
    flex-direction: column;
    position: relative;
    h4{
        margin-top: 1rem;
        line-height: 1.5;
        letter-spacing: 0.14rem;
        font-size: 1.2rem;
    }
    p{
        font-family: var(--font-alt);
        font-size: 0.95rem;
        @media screen and (min-width:2000px) {
            font-size: 1.1rem;
        }
    }
    .project-title{
        color: var(--color-golden);
        text-align: center;
        text-transform: uppercase;
        @media screen and (min-width:2000px) {
            font-size: 1.3rem;
        }
    }
    .desc{
        height: 200px;
        text-align: justify;
        word-wrap: break-word;
        overflow-y: scroll;
        -ms-overflow-style: none;
        scrollbar-width: none;
        &::-webkit-scrollbar{
            display: none;
        }
    }

    .app__projects-tag{
        margin: 0.5rem;
        padding: 0.5rem 1rem;
        text-transform: capitalize;
        background-color: var(--color-crimson);
        p{
            color: var(--black-color);
            font-size: 0.85rem;
        }
        border-radius: 0.5rem;
    }
}