#about{
    color: var(--white-color);
}

.app__about{
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 6rem 0rem 1rem;
}

.app__profiles{
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 2rem;
}

.app__about-item{
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1rem;
    text-align: justify;
    .app__profiles-title{
        color: var(--color-golden);
        font-weight: normal;
        font-family: var(--font-base);
    }
    .app__profiles-description{
        color: var(--color-grey);
        font-family: var(--font-alt);
    }
    img{
        width: 100%;
        height: 170px;
        border-radius: 50px;
        object-fit: contain;
    }

    @media screen and (min-width: 2000px) {
        width: 600px;
        margin: 2rem 2rem;
        img{
            height: 320px;
        }

    }
}