.app__Navbar{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 2rem;
    background-color: transparent;
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    position: fixed;
    z-index: 2;
}

.app__Navbar-logo{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    img{
        height: 70px;
        @media screen and (min-width: 2000px) {
            width: 300px;
        }
        @media screen and (max-width: 450px) {
            height: 60px;
        }
    }
}

.app__Navbar-links{
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    li{
        margin: 0 1rem;
        // cursor: pointer;
        flex-direction: column;
        div{
            width: 5px;
            height: 5px;
            background: transparent;
            border-radius: 50%;
            margin-bottom: 5px;
        }

        a{
            color: var(--white-color);
            text-decoration: none;
            text-transform: capitalize;
            font-size: 1rem;
            font-family: var(--font-alt);
            transition: 0.5s ease;

            &:hover{
                color: var(--color-golden);
                border-bottom: 1px solid var(--color-golden);
            }
        }
        &:hover{
            div{
                background-color: var(--color-golden);
                transition: all 0.3s ease-in-out;
            }
        }
    }
    @media screen and (max-width: 900px) {
        display: none;
    }
}

.app__Navbar-menu{
    width: 35px;
    height: 35px;
    border-radius: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    // background-color: var(--secondary-color);
    background-color: var(--color-golden);

    svg{
        width: 70%;
        height: 70%;
    }

    div{
        display: flex;
        justify-content: flex-end;
        align-items: flex-end;
        flex-direction: column;
        position: fixed;
        top: 0; 
        bottom: 0;
        right: 0;
        margin-right: 4px;
        z-index: 5;
        padding: 1rem;
        width: 80%;
        height: 100vh;
        background-color: var(--color-black);
        background-size: cover;
        background-repeat: repeat;

        box-shadow: 0 0 20px rgba(168,168,168,0.15);

        svg{
            width: 35px;
            height: 35px;
            color: var(--color-golden);
            margin: 0.5rem 1rem;
        }

        ul{
            list-style: none;
            margin: 0;
            padding: 0;
            height: 100%;
            width: 100%;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;
            flex-direction: column;

            li{
                margin: 1rem;
                a{
                    font-family: var(--font-base);
                    color: var(--color-golden);
                    text-decoration: none;
                    letter-spacing: 0.18em;
                    text-transform: capitalize;
                    font-size: 1.2rem;
                    transition: 0.5s ease;

                    &:hover{
                        color: var(--white-color);
                        border-bottom: 1px solid var(--color-golden);
                    }
                }
            }
        }
        @media screen and (min-width: 900px) {
            display: none;
        }
    }
    
    @media screen and (min-width: 900px) {
        display: none;
    }
}