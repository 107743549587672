.app{
    background: url('./assets/bg.png');
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    background-attachment: fixed;
    font-family: var(--font-base);
}

.app_container{
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-direction: row;
}

.app__flex {
    display: flex;
    justify-content: center;
    align-items: center;
}
  
.app__wrapper {
    flex: 1;
    width: 100%;
    flex-direction: column;
    // padding: 4rem 2rem;

    @media screen and (max-width: 450px) {
        padding: 4rem 1rem 2rem;
    }
}

.copyright {
    width: 100%;
    padding: 1rem 0 1rem;

    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;

    p {
        text-transform: capitalize  ;
        color: var(--color-golden);
        font-size: 0.8rem;
    }
}

.head-text {
    font-size: 2.3rem;
    font-weight: 800;
    text-align: center;
    color: var(--white-color);
    letter-spacing: 2px;
    text-transform: capitalize;

    span {
        color: var(--color-golden);
    }

    @media screen and (min-width: 2000px) {
        font-size: 4rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 2rem;
    }
}

.p-text {
    font-size: 1.1rem;
    letter-spacing: 0.15em;
    text-align: left;
    color: var(--white-color);
    // font-family: var(--font-alt);
    line-height: 1.5;

    @media screen and (min-width: 2000px) {
        font-size: 1.75rem;
    }
}

.bold-text {
    font-size: 1rem;
    font-weight: 800;
    color: var(--white-color);
    text-align: left;

    @media screen and (min-width: 2000px) {
        font-size: 2rem;
    }

    @media screen and (max-width: 450px) {
        font-size: 0.9rem;
    }
}

.app__social {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex-direction: column;

    padding: 1rem;

    div {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--color-crimson);
        margin: 0.25rem 0;
        border: 1px solid var(--lightGray-color);

        display: flex;
        justify-content: center;
        align-items: center;

        transition: all 0.1s ease-in-out;
        a{
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 1.2rem;
        }

        svg {
            object-fit: contain;
            color: var(--color-black);
        }

    &:hover {
        // cursor: pointer;
        background-color: var(--color-golden);
        border-color: var(--secondary-color);
        box-shadow: 0 0 20px rgba(#DCCA87, 0.4);

    }

    @media screen and (min-width: 2000px) {
        width: 70px;
        height: 70px;

        margin: 0.5rem 0;

        svg {
            width: 30px;
            height: 30px;
        }
    }
}
}

.app__navigation {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    padding: 1rem;

    .app__navigation-dot {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: var(--color-crimson);
        margin: 0.5rem;

        transition: background-color 0.2s ease-in-out;

        &:hover {
        background-color: var(--color-golden);
        }

        @media screen and (min-width: 2000px) {
        width: 20px;
        height: 20px;
        }
    }
}

@media screen and (max-width: 500px) {
    .app__navigation,
    .app__social {
        display: none;
    }

    .copyright {
        padding: 2rem;
    }
}