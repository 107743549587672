.app__skillsExperience{
    color: var(--white-color);
    flex: 1;
    width: 100%;
    flex-direction: column;
    padding: 6rem 0 1rem;
}

.app__skills-container{
    width: 100%;
    margin-top: 4rem;

    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        width: 100%;
        flex-direction: column;
    }
}

.app__skills-list{
    display: flex;
    flex-wrap: wrap;
    flex: 0.8;
    justify-content: center;
    align-items: flex-start;
    margin-right: 5rem;
    margin-left: 1rem;
    @media screen and (max-width: 900px) {
        margin-right: 0;
        margin-left: 0;
        justify-content: center;
        align-items: center;
    }   
}

.app__skills-item{
    display: flex;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
    margin: 1rem;
    transition: all 0.1s ease-in-out;
    border-radius: 0.5rem;
    padding: 1rem;
    border: 1px solid var(--color-golden);
    &:hover{
        box-shadow: 0 0 25px var(--color-golden);
    }

    div{
        height: 100px;
        width: 100px;
        img{
            width: 70%;
            height: 70%;
        }
        @media screen and (min-width: 2000px) {
            width: 200px;
            height: 200px;
        } 
        @media screen and (max-width: 450px) {
            width: 90px;
            height: 90px;
        } 
    }
    .skill-name{
        font-family: var(--font-alt);
        letter-spacing: normal;
        font-weight: normal;
        font-size: 1rem;
    }
    @media screen and (min-width: 2000px) {
        margin: 1rem 2rem;
        p{
            margin-top: 1rem;
        }
    } 
}

.app__skills-exp{
    display: flex;
    flex-direction: column;
    @media screen and (max-width: 900px) {
        margin-top: 2rem;
    }   
}

.app__experience-list{
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 1rem 0;
    // border: 1px solid var(--color-golden);
    transition: all 0.1s ease-in-out;
    border-radius: 0.5rem;
    padding: 0.8rem;
    cursor: pointer;
    &:hover{
        box-shadow: 0 0 25px var(--color-golden);
    }
    @media screen and (max-width: 900px) {
        margin-right: 0;
        margin-left: 0;
        justify-content: center;
        align-items: center;
        padding: 0.8rem 0rem;
    }   

    .app__skills-exp-img{
        width: 60px;
        height: 60px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow-x: hidden;
        overflow-y: hidden;
        margin: 0 1rem 0;
        img{
            object-fit: cover;
            width: 100%;
            height: 100%;
        }

        @media screen and (max-width: 900px) {
            width: 50px;
            height: 50px;
        }
        @media screen and (max-width: 450px) {
            width: 40px;
            height: 40px;
        }
        @media screen and (min-width: 2000px) {
            width: 80px;
            height: 80px;
        }
    }

    .app__skills-exp-work{
        display: flex;
        flex: 1;
        flex-direction: column;
        .jobtitle{
            font-size: 1.1rem;
            color: var(--color-golden); 
        }
        .company, .year{
            font-family: var(--font-alt);
            font-size: 0.8rem;
            color: var(--color-white);
        }
    }
}

.skills-tooltip{
    max-width: 300px !important;
    text-align: justify !important;
    background-color: var(--color-golden) !important;
    color: var(--black-color) !important;
    font-family: var(--font-alt) !important;
    font-size: 0.9rem !important;
    padding: 1rem !important;
    line-height: 1.5 !important;
    opacity: 1 !important;
    border-radius: 0.5rem !important;

    @media screen and (min-width: 2000px) {
        font-size: 1.5rem !important;
        max-width: 500px !important;
        line-height: 2 !important;
    }
}